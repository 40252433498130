import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import Row from "../components/bootstrap/row"
import SponsorBox from "../components/sponsor-box"
import LakesideSoftwareLogoImage from "../components/lakeside-software-logo-image"
import NFONLogoImage from "../components/nfon-logo-image"
import NHSBSALogoImage from "../components/nhsbsa-logo-image"
import ImgIronMountainLogo from "../components/img-iron-mountain-logo"
import ImgAdobeLogo from "../components/img-adobe-logo"
import WazokuLogoImage from "../components/wazoku-logo-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ImgCipherLabLogo from "../components/img-cipherlab-logo"
import ImgDatbLogo from "../components/img-datb-logo"
import ImgQuadientLogo from "../components/img-quadient-logo"
import ImgOleeoLogo from "../components/img-oleeo-logo"
import ImgPescadoLogo from "../components/img-pescado-logo"
import ImgPrimenetLogo from "../components/img-primenet-logo"
import ImgZivverLogo from "../components/img-zivver-logo"
import ImgMillenniaLogo from "../components/img-millenia-logo"
import ImgSynAppsLogo from "../components/img-synapps-logo"
import ImgCCubeLogo from "../components/img-ccube-logo"

const SponsorsPage = () => (
  <Background>
    <Layout>
      <SEO title="Sponsors &amp; Exhibitors" />
      <Container>
        <PageTitle text="Sponsors &amp; Exhibitors" />
        <p style={{
          textTransform: `uppercase`,
          color: `#fff`,
          fontWeight: `bold`,
          textAlign: `center`,
          maxWidth: `750px`,
          margin: `auto`,
          marginBottom: `50px`
        }}>Smarter Working Live 2020 is dedicated to celebrating the excellent work of the UK Public Sector. This event would not be possible without the outstanding support from our partners:</p>
        
        <Row>
          <div className="offset-lg-1 col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.quadient.com" target="_blank"><ImgQuadientLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="offset-lg-0 col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.lakesidesoftware.com" target="_blank"><LakesideSoftwareLogoImage /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox padding="30px"><OutboundLink href="https://www.nfon.com" target="_blank"><NFONLogoImage /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.nhsbsa.nhs.uk" target="_blank"><NHSBSALogoImage /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.ironmountain.co.uk" target="_blank"><ImgIronMountainLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
        </Row>
        <Row>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox padding="35px"><OutboundLink href="https://acrobat.adobe.com/uk/en/sign/business.html" target="_blank"><ImgAdobeLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox padding="10px"><OutboundLink href="https://ccubesolutions.com" target="_blank"><ImgCCubeLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.cipherlab.com" target="_blank"><ImgCipherLabLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.datb.com" target="_blank"><ImgDatbLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://millennia.cloud" target="_blank"><ImgMillenniaLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox padding="10px"><OutboundLink href="https://www.oleeo.com" target="_blank"><ImgOleeoLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="offset-lg-1 col-6 col-sm-3 col-lg-2">
            <SponsorBox padding="10px"><OutboundLink href="https://pescado.co.uk" target="_blank"><ImgPescadoLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.primenetuk.com" target="_blank"><ImgPrimenetLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.synapps-solutions.com" target="_blank"><ImgSynAppsLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.wazoku.com" target="_blank"><WazokuLogoImage /></OutboundLink></SponsorBox>
            <br />
          </div>
          <div className="col-6 col-sm-3 col-lg-2">
            <SponsorBox><OutboundLink href="https://www.zivver.com" target="_blank"><ImgZivverLogo /></OutboundLink></SponsorBox>
            <br />
          </div>
        </Row>
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  </Background>
)

export default SponsorsPage
